import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  colorSchemes,
  borderRadius,
  fontSizes,
  space,
} from '../../design/theme';

const PillWithIconIcon = styled(FontAwesomeIcon)`
  margin-right: ${space[1]}px;
  color: ${colorSchemes.secondary.fg};
  border-radius: 100%;
  vertical-align: middle;
  display: inline-block;
`;

const PillWithIcon = styled(({ className, children, icon }) => (
  <div className={className}>
    <PillWithIconIcon icon={icon} />
    {children}
  </div>
))`
  background-color: ${transparentize(0.9, colorSchemes.secondary.fg)};
  font-size: ${fontSizes[0]}rem;
  border-radius: ${borderRadius}px;
  padding: ${space[1]}px;
  margin-right: ${space[1]}px;
  margin-top: ${space[1]}px;
  display: inline-block;
`;

PillWithIcon.propTypes = {
  ...PillWithIcon.propTypes,
  icon: PropTypes.shape({}).isRequired,
};

export default PillWithIcon;
