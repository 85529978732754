import React from 'react';
import styled from 'styled-components';
import { Waypoint } from 'react-waypoint';

import {
  faBrowser,
  faFlask,
  faSearch,
  faChalkboardTeacher,
} from '@fortawesome/pro-light-svg-icons';

import { screens, space } from '../../../design/theme';
import { createResponsiveBlock } from '../../../design/util';

import TimelineBubble from './timelineBubble';

const TimelineContainer = styled.div`
  padding: ${space[4]}px 0 ${space[5]}px;

  ${createResponsiveBlock(screens.LAPTOP_SM)} {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }

  ${createResponsiveBlock(screens.LAPTOP_LG)} {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }

  ${createResponsiveBlock(screens.DESKTOP)} {
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }
`;

class Timeline extends React.Component {
  constructor(props) {
    super(props);

    this.handleTimelineSeen = this.handleTimelineSeen.bind(this);

    this.state = {
      seenForFirstTime: false,
    };
  }

  handleTimelineSeen() {
    const { seenForFirstTime } = this.state;
    if (!seenForFirstTime) {
      this.setState({
        seenForFirstTime: true,
      });
    }
  }

  render() {
    const { seenForFirstTime } = this.state;

    return (
      <Waypoint
        onEnter={this.handleTimelineSeen}
        topOffset="75%"
        bottomOffset="100%"
      >
        <TimelineContainer>
          <TimelineBubble
            companyName="Interactive Corporation"
            jobTitle="Web Developer Intern"
            jobLocation="Yonkers, NY"
            icon={faSearch}
            timeFrame="Summer 2011 - Summer 2015"
            animationDelay=".25s"
            isVisible={seenForFirstTime}
          />
          <TimelineBubble
            companyName="General Assembly"
            jobTitle="Engineering Intern"
            jobLocation="New York City, NY"
            icon={faChalkboardTeacher}
            timeFrame="Summer 2016"
            animationDelay=".5s"
            isVisible={seenForFirstTime}
          />
          <TimelineBubble
            companyName="Optimizely, Inc."
            jobTitle="Software Engineer Intern"
            jobLocation="San Francisco, CA"
            icon={faBrowser}
            timeFrame="Summer 2017"
            animationDelay=".75s"
            isVisible={seenForFirstTime}
          />
          <TimelineBubble
            companyName="Optimizely, Inc."
            jobTitle="Software Engineer"
            jobLocation="San Francisco, CA"
            icon={faFlask}
            timeFrame="June 2018 - Present"
            animationDelay="1s"
            isVisible={seenForFirstTime}
            isLastSegment
          />
        </TimelineContainer>
      </Waypoint>
    );
  }
}

export default Timeline;
