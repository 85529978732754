import { Map } from 'immutable';

import {
  CONTACT_FLOW_INITIATED,
  CONTACT_FLOW_ABANDONED,
  MESSAGE_SEND_INITIATED,
  MESSAGE_SEND_COMPLETED,
  MESSAGE_SEND_FAILED,
  SET_LEAD_NAME,
  SET_LEAD_EMAIL_ADDR,
  SET_LEAD_COMPANY,
  SET_LEAD_MESSAGE,
  RESET_FIELDS,
} from './actionTypes';

import { COMPANIES_ORDER, COMPANIES_HUMAN_READABLE } from './constants';

// Create default store state
const defaultState = Map({
  contactFlowActive: false,
  leadCompany: COMPANIES_HUMAN_READABLE[COMPANIES_ORDER[0]],
  leadName: '',
  leadMessage: '',
  leadEmailAddress: '',
  messageIsSending: false,
  messageDidSend: false,
  messageDidFail: false,
  validationErrors: Map({
    leadCompany: null,
    leadName: null,
    leadMessage: null,
    leadEmailAddress: null,
  }),
  isClean: true,
});

// Define action reducers
const contactMe = (state = defaultState, action) => {
  let newState = state;

  switch (action.type) {
    case CONTACT_FLOW_INITIATED:
      if (!newState.get('messageIsSending')) {
        newState = defaultState.set('contactFlowActive', true);
      }
      break;

    case CONTACT_FLOW_ABANDONED:
      newState = defaultState;
      break;

    case MESSAGE_SEND_INITIATED:
      newState = newState
        .set(
          'messageIsSending',
          action.errors.every((error) => !error),
        )
        .set('validationErrors', action.errors);
      break;

    case MESSAGE_SEND_COMPLETED:
      newState = newState
        .set('messageIsSending', false)
        .set('messageDidSend', true)
        .set('messageDidFail', false);
      break;

    case MESSAGE_SEND_FAILED:
      newState = newState
        .set('messageIsSending', false)
        .set('messageDidSend', false)
        .set('messageDidFail', true);
      break;

    case SET_LEAD_NAME:
      if (!newState.get('messageIsSending')) {
        newState = newState
          .set('leadName', action.name)
          .set('isClean', false)
          .setIn(['validationErrors', 'leadName'], action.error);
      }
      break;

    case SET_LEAD_EMAIL_ADDR:
      if (!newState.get('messageIsSending')) {
        newState = newState
          .set('leadEmailAddress', action.emailAddress)
          .set('isClean', false)
          .setIn(['validationErrors', 'leadEmailAddress'], action.error);
      }
      break;

    case SET_LEAD_COMPANY:
      if (!newState.get('messageIsSending')) {
        newState = newState
          .set('leadCompany', action.company)
          .set('isClean', false)
          .setIn(['validationErrors', 'leadCompany'], action.error);
      }
      break;

    case SET_LEAD_MESSAGE:
      if (!newState.get('messageIsSending')) {
        newState = newState
          .set('leadMessage', action.message)
          .set('isClean', false)
          .setIn(['validationErrors', 'leadMessage'], action.error);
      }
      break;

    case RESET_FIELDS:
      if (!newState.get('messageIsSending')) {
        newState = defaultState.set('contactFlowActive', true);
      }
      break;

    default:
      break;
  }

  return newState;
};

export default contactMe;
