/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/no-unresolved */
import React from 'react';
import styled from 'styled-components';
import { darken, transparentize } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RetinaImage from 'react-retina-image';

import {
  faExclamationCircle,
  faSpinnerThird,
  faCheckCircle,
} from '@fortawesome/pro-light-svg-icons';
import { faPaperPlane, faUndo } from '@fortawesome/free-solid-svg-icons';

import Button from '../../../../components/button';
import Emoji from '../../../../components/emoji';

import {
  space,
  borderRadius,
  colorSchemes,
  fontSizes,
  fontWeights,
  screens,
} from '../../../../design/theme';
import { createResponsiveBlock } from '../../../../design/util';

import greetingImage1x from './greetingImage.png';
import greetingImage2x from './greetingImage@2x.png';
import greetingImage3x from './greetingImage@3x.png';

function validateLeadEmailAddress(emailAddress) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return (
    (!emailAddress && 'This field is required.') ||
    (!re.test(String(emailAddress).toLowerCase()) &&
      'Please provide a valid email address.')
  );
}

const ProfilePicImage = styled(RetinaImage).attrs({
  src: [greetingImage1x, greetingImage2x, greetingImage3x],
})`
  width: 150px;
  height: auto;
`;

const ProfilePicTitle = styled.div`
  font-size: ${fontSizes[4]}rem;
  font-weight: ${fontWeights[2]};
  color: ${colorSchemes.secondary.fg};
`;

const ProfilePicSubtitle = styled.div`
  font-size: ${fontSizes[2]}rem;
  color: ${colorSchemes.light.fg};
`;

const ProfilePic = styled(({ className }) => (
  <div className={className}>
    <ProfilePicImage />
    <ProfilePicTitle>
      Hi there <Emoji symbol="👋🏻" label="[hand wave]" />
    </ProfilePicTitle>
    <ProfilePicSubtitle>Please leave your message below.</ProfilePicSubtitle>
  </div>
))`
  margin-bottom: ${space[4]}px;
  text-align: center;
`;

const Input = styled.input`
  padding: ${space[2]}px;
  border-radius: ${borderRadius}px;
  background-color: ${colorSchemes.white.bg};
  display: block;
  width: 100%;
  border: 1px solid ${darken(0.1, colorSchemes.white.bg)};
  transition: 0.25s border-color ease-in-out, box-shadow 0.1s 0.1s ease-in;
  outline: none;
  box-sizing: border-box;
  font-size: ${fontSizes[2]}rem;
  line-height: ${fontSizes[2]}rem;
  height: calc(${fontSizes[2]}em + ${2 * space[2]}px);

  &:focus {
    border-color: ${colorSchemes.white.fg};
    box-shadow: 0 0 0 3px ${transparentize(0.8, colorSchemes.white.fg)};
  }

  &::after {
    content: 'Test';
    display: block;
    color: ${colorSchemes.accent.bg};
  }
`;

const FormGroupLabel = styled.div`
  margin-bottom: ${space[1]}px;
  font-size: ${fontSizes[2]}rem;
  color: ${colorSchemes.light.fg};
`;

const FormGroupError = styled.div`
  border-radius: ${borderRadius}px;
  color: ${colorSchemes.danger.bg};
  margin-top: ${space[1]}px;
  font-size: ${fontSizes[1]}rem;
`;

const FormGroup = styled(({ label, error, children, className }) => (
  <div className={className}>
    <FormGroupLabel>{label}</FormGroupLabel>
    <div>{children}</div>
    {error && (
      <FormGroupError>
        <FontAwesomeIcon icon={faExclamationCircle} /> {error}
      </FormGroupError>
    )}
  </div>
))`
  margin-bottom: ${space[3]}px;
`;

const TextArea = styled(Input.withComponent('textarea'))`
  height: auto;
`;

const Select = styled(Input.withComponent('select'))`
  padding: 0;
  text-indent: 8px;
`;

const ButtonContainer = styled.div`
  flex-shrink: 0;
  text-align: right;

  ${createResponsiveBlock(screens.MOBILE_SM)} {
    text-align: center;
  }

  ${createResponsiveBlock(screens.MOBILE_LG)} {
    text-align: center;
  }

  ${createResponsiveBlock(screens.TABLET)} {
    text-align: center;
  }
`;

const Loader = styled(({ className }) => (
  <div className={className}>
    <FontAwesomeIcon icon={faSpinnerThird} spin />
    <span>Sending your message...</span>
  </div>
))`
  font-size: ${fontSizes[4]}rem;
  color: ${colorSchemes.primary.bg};
  text-align: center;
  padding: ${space[4]}px 0;

  span {
    font-size: ${fontSizes[2]}rem;
    margin-left: ${space[2]}px;
    color: ${colorSchemes.light.fg};
    animation-name: pulse;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in;
  }

  @keyframes pulse {
    0% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }
`;

const SuccessMessageImage = styled(FontAwesomeIcon).attrs({
  icon: faCheckCircle,
})`
  font-size: 128px;
  color: ${colorSchemes.white.fg};
  margin-bottom: ${space[2]}px;
`;

const SuccessMessageTitle = styled.div`
  font-size: ${fontSizes[3]}rem;
  margin-bottom: ${space[1]}px;
  color: ${colorSchemes.white.fg};
`;

const SuccessMessageSubtitle = styled.div`
  font-size: ${fontSizes[1]}rem;
  color: ${colorSchemes.light.fg};
`;

const SuccessMessage = styled(({ className, leadEmailAddress }) => (
  <div className={className}>
    <SuccessMessageImage />
    <SuccessMessageTitle>Your message sent successfully!</SuccessMessageTitle>
    <SuccessMessageSubtitle>
      Thanks for reaching out <Emoji symbol="😊" label="[smiley face]" />
      I&rsquo;ll follow up with you via email ({leadEmailAddress}) shortly.
    </SuccessMessageSubtitle>
  </div>
))`
  text-align: center;
`;

const FailureMessageImage = styled(FontAwesomeIcon).attrs({
  icon: faExclamationCircle,
})`
  font-size: 128px;
  color: ${colorSchemes.danger.bg};
  margin-bottom: ${space[2]}px;
`;

const FailureMessageTitle = styled.div`
  font-size: ${fontSizes[3]}rem;
  margin-bottom: ${space[1]}px;
  color: ${colorSchemes.light.fg};
`;

const FailureMessageSubtitle = styled.div`
  font-size: ${fontSizes[1]}rem;
  color: ${colorSchemes.light.fg};
`;

const FailureMessage = styled(({ className }) => (
  <div className={className}>
    <FailureMessageImage />
    <FailureMessageTitle>
      Oops! Something went wrong <Emoji symbol="🚨" label="[siren icon]" />
    </FailureMessageTitle>
    <FailureMessageSubtitle>
      Unfortunately, your something went wrong and your message was not sent.
      <br />
      Please try again later or email me at zachpwr@gmail.com
    </FailureMessageSubtitle>
  </div>
))`
  text-align: center;
`;

const LegalCaption = styled.div`
  font-size: ${fontSizes[1]}rem;
  padding-right: ${space[3]}px;
  color: ${colorSchemes.light.fg};
  align-self: center;
  flex-grow: 1;

  a {
    color: ${colorSchemes.white.fg};
    font-size: ${fontSizes[1]}rem;
  }

  ${createResponsiveBlock(screens.MOBILE_SM)} {
    margin-bottom: ${space[2]}px;
    padding-right: 0px;
    text-align: center;
  }

  ${createResponsiveBlock(screens.MOBILE_LG)} {
    margin-bottom: ${space[2]}px;
    padding-right: 0px;
    text-align: center;
  }

  ${createResponsiveBlock(screens.TABLET)} {
    margin-bottom: ${space[2]}px;
    padding-right: 0px;
    text-align: center;
  }
`;

const FormBottom = styled.div`
  display: flex;

  ${createResponsiveBlock(screens.MOBILE_SM)} {
    flex-direction: column;
  }

  ${createResponsiveBlock(screens.MOBILE_LG)} {
    flex-direction: column;
  }

  ${createResponsiveBlock(screens.TABLET)} {
    flex-direction: column;
  }
`;

class ContactMeDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSending: false,
      didFail: false,
      didSend: false,
      isClean: true,
      formData: {
        email: '',
        name: '',
        message: '',
      },
      validationErrors: {
        email: null,
        name: null,
        message: null,
      },
    };

    this.setName = this.setName.bind(this);
    this.setEmail = this.setEmail.bind(this);
    this.setMessage = this.setMessage.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    const { isSending, formData } = this.state;

    e.preventDefault();

    if (isSending) {
      return false;
    }

    const validationErrors = {
      name: formData.name.length ? null : 'This field is required.',
      email: validateLeadEmailAddress(formData.email),
      message: formData.message.length ? null : 'This field is required.',
    };

    const isPassingValidation = Object.keys(validationErrors).reduce(
      (result, currentErrorKey) => result && !validationErrors[currentErrorKey],
      true,
    );

    if (!isPassingValidation) {
      this.setState({
        validationErrors,
      });

      return false;
    }

    this.setState({
      isSending: true,
      validationErrors,
    });

    const extendedFormData = {
      ...formData,
      'form-name': 'contact',
    };

    const encodedFormData = Object.keys(extendedFormData)
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(
            extendedFormData[key],
          )}`,
      )
      .join('&');

    return fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encodedFormData,
    })
      .then(() => {
        this.setState({
          didSend: true,
          isSending: false,
          didFail: false,
        });
      })
      .catch(() => {
        this.setState({
          didSend: false,
          isSending: false,
          didFail: true,
        });

        setTimeout(() => {
          this.setState({
            didFail: false,
          });
        }, 5000);
      });
  }

  setName(name) {
    const { formData } = this.state;
    this.setState({
      formData: {
        ...formData,
        name,
      },
      isClean: false,
    });
  }

  setEmail(email) {
    const { formData } = this.state;
    this.setState({
      formData: {
        ...formData,
        email,
      },
      isClean: false,
    });
  }

  setMessage(message) {
    const { formData } = this.state;
    this.setState({
      formData: {
        ...formData,
        message,
      },
      isClean: false,
    });
  }

  resetForm() {
    this.setState({
      formData: {
        name: '',
        email: '',
        message: '',
      },
      isClean: true,
      validationErrors: {
        name: null,
        email: null,
        message: null,
      },
    });
  }

  render() {
    const {
      didFail,
      didSend,
      isSending,
      isClean,
      formData,
      validationErrors,
    } = this.state;

    if (isSending) {
      return <Loader />;
    }

    if (didSend) {
      return <SuccessMessage leadEmailAddress={formData.email} />;
    }

    if (didFail) {
      return <FailureMessage />;
    }

    return (
      <form
        name="contact"
        onSubmit={this.onSubmit}
        netlify-honeypot="bot-field"
        data-netlify="true"
      >
        <ProfilePic />
        <FormGroup label="Name" error={validationErrors.name}>
          <Input
            onChange={(e) => this.setName(e.target.value)}
            value={formData.name}
            disabled={isSending}
            name="name"
          />
        </FormGroup>
        <FormGroup label="Email Address" error={validationErrors.email}>
          <Input
            onChange={(e) => this.setEmail(e.target.value)}
            value={formData.email}
            disabled={isSending}
            name="email"
          />
        </FormGroup>
        <FormGroup label="Message" error={validationErrors.message}>
          <TextArea
            rows={5}
            onChange={(e) => this.setMessage(e.target.value)}
            value={formData.message}
            disabled={isSending}
            name="message"
          />
        </FormGroup>
        <FormBottom>
          <LegalCaption>
            By clicking Send, you are agreeing to the&nbsp;
            <a href="/privacy" target="_blank">
              Privacy Policy and User Agreement
            </a>
            &nbsp;for zachpwr.com.
          </LegalCaption>
          <ButtonContainer>
            <Button
              p={2}
              m={[0, 1, 0, 0]}
              textSize={0}
              colorScheme="white"
              onClick={this.resetForm}
              disabled={isClean || isSending}
              isOutlined
            >
              <FontAwesomeIcon icon={faUndo} /> Reset
            </Button>
            <Button
              p={2}
              textSize={0}
              type="submit"
              disabled={isClean}
              colorScheme="white"
            >
              <FontAwesomeIcon icon={faPaperPlane} /> Send
            </Button>
          </ButtonContainer>
        </FormBottom>
      </form>
    );
  }
}

export default ContactMeDialog;
