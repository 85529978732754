import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons';

import { isResumeAvailable } from '../../config.json';

import Section from '../../components/section';
import Button from '../../components/button';

import {
  pagePadding,
  pagePaddingLargeLaptop,
  pagePaddingDesktop,
  screens,
  space,
  colorSchemes,
  fontSizes,
} from '../../design/theme';
import { createResponsiveBlock } from '../../design/util';

import EducationColumn from './educationColumn';
import SkillsColumn from './skillsColumn';

const ContentContainer = styled.div`
  padding: 0px ${space[pagePadding]}px ${space[5]}px ${space[pagePadding]}px;
  display: block;
  background-color: ${colorSchemes.secondary.bg};

  ${createResponsiveBlock(screens.LAPTOP_SM)} {
    display: flex;
  }

  ${createResponsiveBlock(screens.LAPTOP_LG)} {
    padding: 0px ${space[pagePaddingLargeLaptop]}px ${space[5]}px
      ${space[pagePaddingLargeLaptop]}px;
    display: flex;
  }

  ${createResponsiveBlock(screens.DESKTOP)} {
    padding: 0px ${space[pagePaddingDesktop]}px ${space[5]}px
      ${space[pagePaddingDesktop]}px;
    display: flex;
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
  background-color: ${colorSchemes.secondary.bg};
  padding-bottom: ${space[5]}px;
`;

const DownloadIcon = styled(FontAwesomeIcon).attrs({
  icon: faFilePdf,
})`
  font-size: ${fontSizes[2]}rem;
  vertical-align: middle;
  margin-right: ${space[1]}px;
`;

/* eslint-disable prefer-stateless-function */
class ProfileDetails extends React.Component {
  render() {
    return (
      <Section colorScheme="secondary" id="profile-details-section">
        <div>
          <ContentContainer
            colorScheme="secondary"
            p={[0, pagePadding, 5, pagePadding]}
            isContainer
          >
            <SkillsColumn />
            <EducationColumn />
          </ContentContainer>
          {isResumeAvailable && (
            <ButtonContainer>
              <Button
                p={[2, 3]}
                isOutlined
                textSize={1}
                colorScheme="secondary"
              >
                <DownloadIcon /> Download Résumé (9kb)
              </Button>
            </ButtonContainer>
          )}
        </div>
      </Section>
    );
  }
}
/* eslint-enable prefer-stateless-function */

export default ProfileDetails;
