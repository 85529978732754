/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/no-unresolved */
import React from 'react';
import scrollToComponent from 'react-scroll-to-component';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { Helmet } from 'react-helmet';
import { normalize } from 'polished';

import theme, { fontFamilyName, fontWeights } from '../design/theme';

import scrollSpyConstants from '../modules/scrollSpy/constants';
import contactMeActions from '../modules/contactMe/actions';

import Drawer from '../components/drawer';
import Nav from '../components/nav';

import LandingSection from '../sections/landing';
import WorkExperienceSection from '../sections/workExperience';
import ProjectsSection from '../sections/projects';
import ProfileDetailsSection from '../sections/profileDetails';
import SidebarNav from '../sections/sidebarNav';
import Footer from '../sections/footer';
import DialogManager from '../sections/dialogManager';

import rootReducer from '../modules/rootReducer';

import favicon from './favicon.png';

const googleFontId = fontFamilyName.split(' ').join('+');
const fontWeightString = fontWeights.join(',');

const GlobalStyle = createGlobalStyle`
  ${normalize()}

  * {
    font-family: '${fontFamilyName}', sans-serif !important;
  }

  .layout {
    @include make-container();
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
  }

  hr {
    background-color: transparent;
    border-style: solid;
    border-width: 1px 0 0 0;
  }
`;

const reduxStore = createStore(rootReducer, {});

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.setWorkExperienceSectionRef = this.setWorkExperienceSectionRef.bind(
      this,
    );
    this.setProjectsSectionRef = this.setProjectsSectionRef.bind(this);
    this.setProfileDetailsSectionRef = this.setProfileDetailsSectionRef.bind(
      this,
    );
    this.navigateTo = this.navigateTo.bind(this);
    this.handleStoreChange = this.handleStoreChange.bind(this);
    this.initiateContactFlow = this.initiateContactFlow.bind(this);

    this.store = reduxStore;
    this.unsubscribe = () => {};

    this.state = {
      isNavFixed: false,
      colorScheme: 'primary',
      canScroll: true,
    };
  }

  componentDidMount() {
    this.unsubscribe = this.store.subscribe(this.handleStoreChange);
  }

  componentWillUnmount() {
    this.unsubscribe();
    this.unsubscribe = () => {};
  }

  setWorkExperienceSectionRef(element) {
    this.workExperienceSectionRef = element;
  }

  setProjectsSectionRef(element) {
    this.projectsSectionRef = element;
  }

  setProfileDetailsSectionRef(element) {
    this.profileDetailsSectionRef = element;
  }

  navigateTo(section) {
    const options = {
      align: 'top',
      offset: 10,
    };

    switch (section) {
      case '#workExperience':
        scrollToComponent(this.workExperienceSectionRef, options);
        break;
      case '#projects':
        scrollToComponent(this.projectsSectionRef, options);
        break;
      case '#profileDetails':
        scrollToComponent(this.profileDetailsSectionRef, options);
        break;
      default:
        break;
    }
  }

  initiateContactFlow() {
    contactMeActions.handleContactFlowInitiated(this.store.dispatch);
  }

  handleStoreChange() {
    const { scrollSpyReducers, contactMeReducers } = this.store.getState();
    const { canScroll: prevCanScroll } = this.state;

    const isNavFixed =
      !!scrollSpyReducers.get('topSection') &&
      scrollSpyReducers.get('topSection') !==
        scrollSpyConstants.SECTIONS.LANDING;
    const colorScheme = scrollSpyReducers.get('colorScheme');
    const canScroll = !contactMeReducers.get('contactFlowActive');

    if (canScroll !== prevCanScroll) {
      document.body.style.overflow = canScroll ? 'auto' : 'hidden';
    }

    this.setState({
      isNavFixed,
      colorScheme,
      canScroll,
    });
  }

  render() {
    const headTags = (
      <Helmet
        titleTemplate="Zach Power - %s"
        defaultTitle="Zach Power - Software Engineer in San Francisco, CA"
      >
        <link rel="canonical" href="https://www.zachpwr.com/" />
        <link rel="icon" type="image/png" href={favicon} />
        <meta charset="UTF-8" />
        <meta name="robots" content="index,follow" />
        <meta
          name="keywords"
          content="software,engineer,san francisco,bay area,recruiting,resume,portfolio,profile,experience,frontend,optimizely,linkedin,developer,javascript,terraform,infrastructure,aws,applications,cloud,app,full stack,fullstack,frontend,backend,senior,years"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta
          name="description"
          content="View the profile of Zach Power, a software engineer from San Francisco, CA - Specialized in frontend development - 2 years of experience + 6 internships"
        />
        <style>
          {`@import url('https://fonts.googleapis.com/css?family=${googleFontId}:${fontWeightString}');`}
        </style>
      </Helmet>
    );

    const { isNavFixed, colorScheme } = this.state;

    return (
      <Provider store={this.store}>
        <div className="layout">
          {headTags}
          <GlobalStyle />
          <ThemeProvider theme={theme}>
            <div>
              <Drawer
                isVisible={isNavFixed}
                enterFrom="top"
                overlay={theme.overlays.TOOLBAR_NAV}
              >
                <Nav
                  colorScheme={colorScheme}
                  isTransparent={!isNavFixed}
                  onNavigate={this.navigateTo}
                  onContactButtonClick={this.initiateContactFlow}
                  isVisible={isNavFixed}
                />
              </Drawer>

              <SidebarNav
                onNavigate={this.navigateTo}
                onContactButtonClick={this.initiateContactFlow}
              />

              <LandingSection
                onNavigate={this.navigateTo}
                onContactButtonClick={this.initiateContactFlow}
              />

              <WorkExperienceSection ref={this.setWorkExperienceSectionRef} />

              <ProjectsSection ref={this.setProjectsSectionRef} />

              <ProfileDetailsSection ref={this.setProfileDetailsSectionRef} />

              <Footer />

              <DialogManager />
            </div>
          </ThemeProvider>
        </div>
      </Provider>
    );
  }
}

IndexPage.propTypes = {};

export default IndexPage;
