import { SECTION_ENTERED_SCREEN, SECTION_EXITED_SCREEN } from './actionTypes';

export const handleSectionEnteringScreen = (sectionId, sectionColorScheme) => ({
  type: SECTION_ENTERED_SCREEN,
  sectionId,
  sectionColorScheme,
});

export const handleSectionExitingScreen = (sectionId, sectionColorScheme) => ({
  type: SECTION_EXITED_SCREEN,
  sectionId,
  sectionColorScheme,
});

export default {
  handleSectionEnteringScreen,
  handleSectionExitingScreen,
};
