import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';
import { transparentize } from 'polished';
import { connect } from 'react-redux';

import Button from '../../components/button';

import {
  overlays,
  overlayOrder,
  screens,
  space,
  colorSchemes,
} from '../../design/theme';
import { getBackground, createResponsiveBlock } from '../../design/util';

import { actions as uiActions } from '../../modules/ui';

const SidebarOverlayWrapper = styled.div`
  z-index: ${(props) =>
    props.isVisible ? overlayOrder.indexOf(overlays.SIDEBAR_NAV) : -1};
  width: 100%;
  min-height: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  transition-property: z-index;
  transition-delay: ${(props) => (props.isVisible ? 0 : 1)}s;
  transition-timing-function: steps(1);
`;

const getOverlayBackgroundColor = ({ isVisible }) =>
  transparentize(isVisible ? 0.5 : 1.0, colorSchemes.light.fg);

const SidebarOverlay = styled.div`
  z-index: 0;
  background-color: ${getOverlayBackgroundColor};
  width: 100%;
  min-height: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  transition-property: background-color;
  transition-duration: 1s;
  transition-timing-function: ease-in;
`;

const getSidebarAnimationName = (props) => {
  if (props.hasOpenedBefore) {
    return props.isVisible ? 'isSidebarVisible' : 'isSidebarNotVisible';
  }
  return 'none';
};

const SidebarContainer = styled.div`
  ${getBackground}
  z-index: 1;
  position: absolute;
  right: ${(props) => (props.isVisible ? 0 : -50)}%;
  width: 75%;
  height: 100%;
  min-height: 100%;
  top: 0;
  bottom: 0;
  padding: ${space[4]}px;
  box-sizing: border-box;
  animation-name: ${getSidebarAnimationName};
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  opacity: ${(props) => (props.isVisible ? 1 : 0)}.0;

  ${createResponsiveBlock(screens.LAPTOP_SM)} {
    width: 50%;
  }

  ${createResponsiveBlock(screens.LAPTOP_LG)} {
    width: 25%;
  }

  ${createResponsiveBlock(screens.DESKTOP)} {
    width: 25%;
  }

  @keyframes isSidebarVisible {
    0% {
      opacity: 0;
      right: -75%;

      ${createResponsiveBlock(screens.LAPTOP_SM)} {
        right: -50%;
      }

      ${createResponsiveBlock(screens.LAPTOP_LG)} {
        right: -25%;
      }

      ${createResponsiveBlock(screens.DESKTOP)} {
        right: -25%;
      }
    }

    100% {
      opacity: 1;
      right: 0%;
    }
  }

  @keyframes isSidebarNotVisible {
    0% {
      opacity: 1;
      right: 0%;
    }

    100% {
      opacity: 0;
      right: -75%;

      ${createResponsiveBlock(screens.LAPTOP_SM)} {
        right: -50%;
      }

      ${createResponsiveBlock(screens.LAPTOP_LG)} {
        right: -25%;
      }

      ${createResponsiveBlock(screens.DESKTOP)} {
        right: -25%;
      }
    }
  }
`;

const NavItem = styled(Button).attrs({
  isFullWidth: true,
  p: 2,
  colorScheme: 'light',
  isOutlined: (props) => props.isOutlined || true,
})`
  margin-bottom: ${space[2]}px;
`;

class SidebarNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasOpenedBefore: false,
    };

    this.componentDidUpdate = () => {
      if (this.props.isVisible && !this.state.hasOpenedBefore) {
        this.setState({
          hasOpenedBefore: true,
        });
      }
    };

    this.onNavigate = (section) => {
      this.props.onDismiss();
      this.props.onNavigate(section);
    };

    this.handleContactButtonClicked = () => {
      this.props.onDismiss();
      this.props.onContactButtonClick();
    };
  }

  render() {
    const { isVisible, onDismiss, colorScheme } = this.props;

    return (
      <SidebarOverlayWrapper
        isVisible={isVisible}
        hasOpenedBefore={this.state.hasOpenedBefore}
      >
        <SidebarOverlay
          onClick={onDismiss}
          isVisible={isVisible}
          hasOpenedBefore={this.state.hasOpenedBefore}
        />
        <SidebarContainer
          isVisible={isVisible}
          colorScheme={colorScheme}
          hasOpenedBefore={this.state.hasOpenedBefore}
          shouldFade
        >
          {isVisible && (
            <FocusLock autoFocus returnFocus>
              <NavItem
                colorScheme={colorScheme}
                shouldFade
                p={2}
                m={[0, 2, 0, 0]}
                textSize={1}
                isOutlined={false}
                onClick={this.handleContactButtonClicked}
                tabIndex={isVisible ? null : -1}
              >
                Contact Me
              </NavItem>
              <NavItem
                colorScheme={colorScheme}
                shouldFade
                p={2}
                m={[0, 2, 0, 0]}
                onClick={() => this.onNavigate('#workExperience')}
                textSize={1}
                tabIndex={isVisible ? null : -1}
                isLink
              >
                Work Experience
              </NavItem>
              <NavItem
                colorScheme={colorScheme}
                shouldFade
                p={2}
                m={[0, 2, 0, 0]}
                onClick={() => this.onNavigate('#projects')}
                textSize={1}
                tabIndex={isVisible ? null : -1}
                isLink
              >
                Projects
              </NavItem>
              <NavItem
                colorScheme={colorScheme}
                shouldFade
                p={2}
                m={[0, 2, 0, 0]}
                onClick={() => this.onNavigate('#profileDetails')}
                textSize={1}
                tabIndex={isVisible ? null : -1}
                isLink
              >
                Skills
              </NavItem>
            </FocusLock>
          )}
        </SidebarContainer>
      </SidebarOverlayWrapper>
    );
  }
}

SidebarNav.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  colorScheme: PropTypes.string.isRequired,
  onNavigate: PropTypes.func.isRequired,
  onContactButtonClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isVisible: state.uiReducers.get('isSidebarNavShowing'),
  colorScheme: state.scrollSpyReducers.get('colorScheme'),
});

const mapDispatchToProps = (dispatch) => ({
  onDismiss: () => uiActions.dismissSidebarNav(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarNav);
