import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { screens, space } from '../../../../design/theme';
import { createResponsiveBlock } from '../../../../design/util';

import Bubble from './bubble';
import LineSegment from './lineSegment';

const TimelineBubbleContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  max-width: 100%;

  ${createResponsiveBlock(screens.LAPTOP_SM)} {
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    margin-right: ${(props) => (props.isLastSegment ? 0 : space[3])}px;
  }

  ${createResponsiveBlock(screens.LAPTOP_LG)} {
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    margin-right: ${(props) => (props.isLastSegment ? 0 : space[3])}px;
  }

  ${createResponsiveBlock(screens.DESKTOP)} {
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    margin-right: ${(props) => (props.isLastSegment ? 0 : space[3])}px;
  }
`;

const TimelineBubble = ({
  companyName,
  jobTitle,
  jobLocation,
  icon,
  timeFrame,
  isLastSegment,
  animationDelay,
  isVisible,
}) => (
  <TimelineBubbleContainer isLastSegment={isLastSegment}>
    <Bubble
      companyName={companyName}
      jobTitle={jobTitle}
      jobLocation={jobLocation}
      icon={icon}
      isLastSegment={isLastSegment}
      timeFrame={timeFrame}
      animationDelay={animationDelay}
      isVisible={isVisible}
    />
    <LineSegment timeFrame={timeFrame} isLastSegment={isLastSegment} />
  </TimelineBubbleContainer>
);

TimelineBubble.propTypes = {
  companyName: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  jobLocation: PropTypes.string.isRequired,
  icon: PropTypes.shape({}).isRequired,
  timeFrame: PropTypes.string.isRequired,
  isLastSegment: PropTypes.bool,
  animationDelay: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

TimelineBubble.defaultProps = {
  isLastSegment: false,
};

export default TimelineBubble;
