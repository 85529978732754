import React from 'react';
import styled from 'styled-components';

import {
  faCogs,
  faMicrochip,
  faKeyboard,
  faChalkboardTeacher,
  faAward,
  faGraduationCap,
} from '@fortawesome/free-solid-svg-icons';
import {
  faTally,
  faIntegral,
  faFunction,
  faLockAlt,
} from '@fortawesome/pro-solid-svg-icons';

import Label from '../../components/label';

import TitledColumn from './titledColumn';
import TitledRow from './titledRow';
import PillWithIcon from './pillWithIcon';

const ColumnContainer = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const EducationColumn = () => (
  <ColumnContainer>
    <TitledColumn title="Education">
      <TitledRow title="University">
        <Label textSize={1} boldness={1} colorScheme="light">
          Binghamton University | Thomas J. Watson School of Engineering
        </Label>
        <PillWithIcon icon={faGraduationCap}>Class of 2018</PillWithIcon>
      </TitledRow>

      <TitledRow title="Degree">
        <Label textSize={1} boldness={1} colorScheme="light">
          Bachelor of Science in Computer Engineering
        </Label>
        <PillWithIcon icon={faAward}>
          Cum Laude Latin Honors Recipient
        </PillWithIcon>
      </TitledRow>

      <TitledRow title="Coursework">
        <PillWithIcon icon={faTally}>Discrete Mathematics</PillWithIcon>
        <PillWithIcon icon={faIntegral}>Calculus I &amp; II</PillWithIcon>
        <PillWithIcon icon={faFunction}>Digital Logic Design</PillWithIcon>
        <PillWithIcon icon={faLockAlt}>Security Engineering</PillWithIcon>
        <PillWithIcon icon={faCogs}>Operating Systems</PillWithIcon>
        <PillWithIcon icon={faChalkboardTeacher}>
          Teaching Practicum
        </PillWithIcon>
        <PillWithIcon icon={faMicrochip}>Computer Architecture</PillWithIcon>
        <PillWithIcon icon={faKeyboard}>Programming I &amp; II</PillWithIcon>
      </TitledRow>
    </TitledColumn>
  </ColumnContainer>
);

export default EducationColumn;
