import escapeHTML from 'escape-html';
import { Map } from 'immutable';

import {
  CONTACT_FLOW_INITIATED,
  CONTACT_FLOW_ABANDONED,
  MESSAGE_SEND_INITIATED,
  MESSAGE_SEND_COMPLETED,
  MESSAGE_SEND_FAILED,
  SET_LEAD_NAME,
  SET_LEAD_EMAIL_ADDR,
  SET_LEAD_COMPANY,
  SET_LEAD_MESSAGE,
  RESET_FIELDS,
} from './actionTypes';

// Validators
function validateLeadName(name) {
  return !name && 'This field is required.';
}

function validateLeadEmailAddress(emailAddress) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return (
    (!emailAddress && 'This field is required.') ||
    (!re.test(String(emailAddress).toLowerCase()) &&
      'Please provide a valid email address.')
  );
}

function validateLeadCompany(company) {
  return !company && 'This field is required.';
}

function validateLeadMessage(message) {
  return !message && 'This field is required.';
}

function validateFormObject(formObj) {
  return Map({
    leadName: validateLeadName(formObj.leadName),
    leadEmailAddress: validateLeadEmailAddress(formObj.leadEmailAddress),
    leadMessage: validateLeadMessage(formObj.leadMessage),
  });
}

export const handleContactFlowInitiated = (dispatch) => {
  dispatch({
    type: CONTACT_FLOW_INITIATED,
  });
};

export const handleContactFlowAbandoned = (dispatch) => {
  dispatch({
    type: CONTACT_FLOW_ABANDONED,
  });
};

export default {
  handleContactFlowInitiated,
  handleContactFlowAbandoned,
};
