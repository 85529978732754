/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import RetinaImage from 'react-retina-image';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faBusinessTime,
  faChevronDoubleDown,
} from '@fortawesome/pro-light-svg-icons';

import Label from '../../components/label';
import Nav from '../../components/nav';
import Section from '../../components/section';

import { SECTIONS } from '../../modules/scrollSpy/constants';

import {
  colorSchemes,
  space,
  fontSizes,
  pagePadding,
  pagePaddingLargeLaptop,
  pagePaddingDesktop,
  screens,
  fontWeights,
} from '../../design/theme';
import util, { createResponsiveBlock } from '../../design/util';

import backgroundVideo from './images/background.mp4';
import backgroundImage from './images/background.png';

const JumbotronWrapper = styled.div`
  background-image: url(${backgroundImage});
  background-position: bottom right;
  background-size: cover;
  flex-direction: column;
  justify-content: space-between;
  min-height: 98vh;
  overflow-x: hidden;
  position: relative;
  ${util.getGrid}
`;

const BackgroundVideo = styled(({ className, children }) => (
  <div className={className}>
    <video autoPlay loop muted>
      {children}
    </video>
  </div>
))`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 0;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: auto;
    height: auto;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }

  ${createResponsiveBlock(screens.MOBILE_SM)} {
    display: none;

    video {
      display: none;
    }
  }

  ${createResponsiveBlock(screens.MOBILE_LG)} {
    display: none;

    video {
      display: none;
    }
  }

  ${createResponsiveBlock(screens.TABLET)} {
    display: none;

    video {
      display: none;
    }
  }
`;

const FixedHeightContainer = styled.div`
  flex-shrink: 1;
  flex-grow: 0;
  position: relative;
  z-index: 1;
`;

const FlexHeightContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
`;

const TitleLabel = styled(Label.withComponent('h1'))`
  padding: 0 ${space[pagePadding]}px;
  margin: 0;
  font-size: ${fontSizes[7]}em;

  ${createResponsiveBlock(screens.MOBILE_SM)} {
    font-size: ${fontSizes[4]}em;
  }

  ${createResponsiveBlock(screens.MOBILE_LG)} {
    font-size: ${fontSizes[5]}em;
  }

  ${createResponsiveBlock(screens.LAPTOP_LG)} {
    padding: 0 ${space[pagePaddingLargeLaptop]}px;
  }

  ${createResponsiveBlock(screens.DESKTOP)} {
    padding: 0 ${space[pagePaddingDesktop]}px;
  }
`;

const SubtitleLabel = styled(Label.withComponent('h2'))`
  color: ${transparentize(0.25, colorSchemes.primary.fg)};
  padding: 0 ${space[pagePadding]}px;
  margin: 0;
  font-size: ${fontSizes[6]}em;

  ${createResponsiveBlock(screens.MOBILE_SM)} {
    font-size: ${fontSizes[2]}em;
  }

  ${createResponsiveBlock(screens.MOBILE_LG)} {
    font-size: ${fontSizes[3]}em;
  }

  ${createResponsiveBlock(screens.LAPTOP_LG)} {
    padding: 0 ${space[pagePaddingLargeLaptop]}px;
  }

  ${createResponsiveBlock(screens.DESKTOP)} {
    padding: 0 ${space[pagePaddingDesktop]}px;
  }
`;

const SmallLabel = styled(Label.withComponent('h3'))`
  color: ${transparentize(0.25, colorSchemes.primary.fg)};
  padding: 0 ${space[pagePadding]}px;
  font-size: ${fontSizes[4]}em;

  svg {
    margin-right: ${space[2]}px;
  }

  ${createResponsiveBlock(screens.MOBILE_SM)} {
    font-size: ${fontSizes[1]}em;
  }

  ${createResponsiveBlock(screens.MOBILE_LG)} {
    font-size: ${fontSizes[2]}em;
  }

  ${createResponsiveBlock(screens.TABLET)} {
  }

  ${createResponsiveBlock(screens.LAPTOP_LG)} {
    padding: 0 ${space[pagePaddingLargeLaptop]}px;
  }

  ${createResponsiveBlock(screens.DESKTOP)} {
    padding: 0 ${space[pagePaddingDesktop]}px;
  }
`;

const CallToActionLabel = styled(Label)`
  line-height: ${fontSizes[5]}rem;
  padding: ${space[3]}px ${space[pagePadding]}px;
  font-size: ${fontSizes[3]}em;

  @keyframes oscillateIcon {
    0% {
      top: -5px;
    }

    100% {
      top: 5px;
    }
  }

  svg {
    position: relative;
    margin-right: ${space[2]}px;
    animation-name: oscillateIcon;
    animation-duration: 1s;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

  ${createResponsiveBlock(screens.LAPTOP_LG)} {
    padding: ${space[3]}px ${space[pagePaddingLargeLaptop]}px;
  }

  ${createResponsiveBlock(screens.DESKTOP)} {
    padding: ${space[3]}px ${space[pagePaddingDesktop]}px;
  }

  ${createResponsiveBlock(screens.MOBILE_SM)} {
    font-size: ${fontSizes[1]}em;
    font-weight: ${fontWeights[1]};
  }

  ${createResponsiveBlock(screens.MOBILE_LG)} {
    font-size: ${fontSizes[2]}em;
    font-weight: ${fontWeights[1]};
  }
`;

const LandingSection = ({ onNavigate, onContactButtonClick, isVisible }) => (
  <Section colorScheme="primary" id="landing-section">
    <JumbotronWrapper isContainer>
      <FixedHeightContainer>
        <Nav
          colorScheme="primary"
          isTransparent
          onNavigate={onNavigate}
          onContactButtonClick={onContactButtonClick}
          isVisible={isVisible}
        />
      </FixedHeightContainer>
      <FlexHeightContainer>
        <TitleLabel boldness={2} colorScheme="primary" p={[0, pagePadding]}>
          Software Engineer
        </TitleLabel>
        <SubtitleLabel boldness={1} colorScheme="primary">
          Applications &amp; Infrastructure
        </SubtitleLabel>
        <SmallLabel boldness={0} colorScheme="primary" m={[2, 0, 0, 0]}>
          <FontAwesomeIcon icon={faBusinessTime} /> 2 years of experience + 6
          internships
        </SmallLabel>
        <SmallLabel boldness={0} colorScheme="primary" m={[2, 0, 0, 0]}>
          <FontAwesomeIcon icon={faHome} /> San Francisco, California
        </SmallLabel>
      </FlexHeightContainer>
      <FixedHeightContainer>
        <CallToActionLabel boldness={0} colorScheme="primary">
          <FontAwesomeIcon icon={faChevronDoubleDown} /> Scroll down for more
          info
        </CallToActionLabel>
      </FixedHeightContainer>
      <BackgroundVideo>
        <source src={backgroundVideo} type="video/mp4" />
      </BackgroundVideo>
    </JumbotronWrapper>
  </Section>
);

LandingSection.propTypes = {
  onNavigate: PropTypes.func.isRequired,
  onContactButtonClick: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

LandingSection.defaultProps = {};

function mapStateToProps({ scrollSpyReducers }) {
  return {
    isVisible: scrollSpyReducers.get('topSection') === SECTIONS.LANDING,
  };
}

export default connect(mapStateToProps, null)(LandingSection);
