import { combineReducers } from 'redux';

import scrollSpyReducers from './scrollSpy/reducers';
import uiReducers from './ui/reducers';
import contactMeReducers from './contactMe/reducers';

const combinedReducer = combineReducers({
  scrollSpyReducers,
  uiReducers,
  contactMeReducers,
});

export default function (state, action) {
  // Create console group
  console.groupCollapsed(`%c[ZP][REDUX]${action.type}`, 'color: purple'); // eslint-disable-line no-console

  // Display the inputs to the next-state logic
  console.info('Action: ', action); // eslint-disable-line no-console
  console.info('Previous State', state); // eslint-disable-line no-console

  // Compute and report the new state
  const newState = combinedReducer(state, action);
  console.info('New State: ', newState); // eslint-disable-line no-console
  console.groupEnd(); // eslint-disable-line no-console

  return newState;
}
