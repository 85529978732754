import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Box from '../../../../components/box';
import Label from '../../../../components/label';

import {
  colorSchemes,
  space,
  fontSizes,
  screens,
  borderRadius,
} from '../../../../design/theme';
import { createResponsiveBlock } from '../../../../design/util';

const BubbleContainer = styled(Box)`
  position: relative;
  margin-bottom: ${(props) => (props.isLastSegment ? 0 : space[2])}px;
  display: flex;
  border-radius: ${borderRadius}px;
  padding: ${space[3]}px;
  flex-grow: 1;
  align-items: center;
  opacity: 0;
  animation-name: ${(props) =>
    props.isVisible ? 'bubbleEnterAnimation' : 'none'};
  animation-delay: ${(props) => props.animationDelay};
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0, 1.01, 0.83, 1.1);

  @keyframes bubbleEnterAnimation {
    0% {
      transform: scaleX(0) scaleY(0) rotate(5deg);
      opacity: 0;
    }

    100% {
      transform: scaleX(1) scaleY(1) rotate(0deg);
      opacity: 1;
    }
  }

  ${createResponsiveBlock(screens.LAPTOP_SM)} {
    display: block;
    margin-bottom: ${space[3]}px;
  }

  ${createResponsiveBlock(screens.LAPTOP_LG)} {
    display: block;
    margin-bottom: ${space[3]}px;
  }

  ${createResponsiveBlock(screens.DESKTOP)} {
    display: block;
    margin-bottom: ${space[3]}px;
  }

  &:before {
    content: '';
    width: ${space[2]}px;
    height: ${space[2]}px;
    display: block;
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    background-color: ${colorSchemes.white.bg};

    ${createResponsiveBlock(screens.LAPTOP_SM)} {
      top: 100%;
      left: 50%;
    }

    ${createResponsiveBlock(screens.LAPTOP_LG)} {
      top: 100%;
      left: 50%;
    }

    ${createResponsiveBlock(screens.DESKTOP)} {
      top: 100%;
      left: 50%;
    }
  }
`;

const TextContainer = styled.div`
  flex-grow: 1;
  padding-left: ${space[2]}px;

  ${createResponsiveBlock(screens.LAPTOP_SM)} {
    flex-grow: 0;
    padding-left: 0;
  }

  ${createResponsiveBlock(screens.LAPTOP_LG)} {
    flex-grow: 0;
    padding-left: 0;
  }

  ${createResponsiveBlock(screens.DESKTOP)} {
    flex-grow: 0;
    padding-left: 0;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${colorSchemes.white.fg};
  font-size: ${fontSizes[2]}rem;
`;

const IconCircle = styled(({ icon, ...props }) => (
  <div {...props}>
    <Icon icon={icon} />
  </div>
))`
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
  border-radius: 50px;
  background-color: ${colorSchemes.secondary.bg};
  text-align: center;
  margin: 0 auto;
  flex-grow: 0;
  flex-shrink: 0;

  svg {
    color: ${colorSchemes.white.fg};
  }

  ${createResponsiveBlock(screens.MOBILE_SM)} {
    display: none;
  }
`;

const CenteredLabel = styled(Label)`
  text-align: center;
`;

const JobLocationLabel = styled(CenteredLabel)`
  &::after {
    content: '${(props) => props.timeFrame}';
    display: block;
    color: ${colorSchemes.primary.bg};
    margin-top: ${space[0]}px;

    ${createResponsiveBlock(screens.LAPTOP_SM)} {
      content: '';
    }

    ${createResponsiveBlock(screens.LAPTOP_LG)} {
      content: '';
    }

    ${createResponsiveBlock(screens.DESKTOP)} {
      content: '';
    }
  }
`;

const TimelineBubble = ({
  companyName,
  jobTitle,
  jobLocation,
  icon,
  timeFrame,
  ...props
}) => (
  <BubbleContainer colorScheme="white" hasShadow {...props}>
    <IconCircle icon={icon} />
    <TextContainer>
      <CenteredLabel
        colorScheme="white"
        textSize={2}
        boldness={2}
        m={[2, 0, 0, 0]}
      >
        {companyName}
      </CenteredLabel>
      <CenteredLabel colorScheme="light" textSize={1} boldness={1}>
        {jobTitle}
      </CenteredLabel>
      <JobLocationLabel
        colorScheme="light"
        textSize={1}
        boldness={0}
        m={[2, 0, 0, 0]}
        timeFrame={timeFrame}
      >
        {jobLocation}
      </JobLocationLabel>
    </TextContainer>
  </BubbleContainer>
);

TimelineBubble.propTypes = {
  companyName: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  jobLocation: PropTypes.string.isRequired,
  icon: PropTypes.shape({}).isRequired,
  timeFrame: PropTypes.string.isRequired,
  animationDelay: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

export default TimelineBubble;
