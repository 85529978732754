import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { space, screens } from '../../design/theme';
import { createResponsiveBlock } from '../../design/util';

import Label from '../../components/label';

const RowContainer = styled.div`
  padding-right: ${(props) => (props.isHorizontal ? space[4] : 0)}px;
  padding-top: 0;
  padding-bottom: ${space[3]}px;

  ${createResponsiveBlock(screens.DESKTOP)} {
    padding-top: ${space[2]}px;
    padding-bottom: 0;
  }
`;

const RowContent = styled.div`
  padding-top: ${space[2]}px;
`;

const TitleLabel = styled(Label.withComponent('h5'))`
  margin: 0;
`;

const TitledRow = ({ title, children, isHorizontal }) => (
  <RowContainer isHorizontal={isHorizontal}>
    <TitleLabel
      textSize={1}
      boldness={2}
      p={[2, 0, 0, 0]}
      isAllCaps
      colorScheme="white"
    >
      {title}
    </TitleLabel>
    <RowContent>{children}</RowContent>
  </RowContainer>
);

TitledRow.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isHorizontal: PropTypes.bool,
};

TitledRow.defaultProps = {
  isHorizontal: false,
};

export default TitledRow;
