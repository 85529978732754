import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { transparentize } from 'polished';

import Label from '../../../../components/label';

import { colorSchemes, space, screens } from '../../../../design/theme';
import { createResponsiveBlock } from '../../../../design/util';

const TimelineBarContainer = styled.div`
  ${createResponsiveBlock(screens.LAPTOP_SM)} {
    margin-right: ${(props) => (props.isLastSegment ? 0 : -1 * space[3])}px;
  }

  ${createResponsiveBlock(screens.LAPTOP_LG)} {
    margin-right: ${(props) => (props.isLastSegment ? 0 : -1 * space[3])}px;
  }

  ${createResponsiveBlock(screens.DESKTOP)} {
    margin-right: ${(props) => (props.isLastSegment ? 0 : -1 * space[3])}px;
  }
`;

const TimelineBar = styled.div`
  height: 100%;
  width: 2px;
  background-color: ${transparentize(0.8, colorSchemes.primary.bg)};
  position: relative;
  margin-right: ${space[3]}px;

  &::before {
    content: ' ';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    transform: translateX(-50%) translateY(-50%);
    background-color: ${colorSchemes.primary.bg};
    margin-top: ${(props) => (props.isLastSegment ? 0 : space[2] / -2)}px;
    margin-left: 0;
  }

  ${createResponsiveBlock(screens.LAPTOP_SM)} {
    height: 2px;
    width: 100%;

    &::before {
      margin-top: 0;
      margin-left: ${(props) => (props.isLastSegment ? 0 : space[3] / -2)}px;
    }
  }

  ${createResponsiveBlock(screens.LAPTOP_LG)} {
    height: 2px;
    width: 100%;

    &::before {
      margin-top: 0;
      margin-left: ${(props) => (props.isLastSegment ? 0 : space[3] / -2)}px;
    }
  }

  ${createResponsiveBlock(screens.DESKTOP)} {
    height: 2px;
    width: 100%;

    &::before {
      margin-top: 0;
      margin-left: ${(props) => (props.isLastSegment ? 0 : space[3] / -2)}px;
    }
  }
`;

const TimelineCaption = styled(Label)`
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  display: none;

  ${createResponsiveBlock(screens.LAPTOP_SM)} {
    margin-right: ${(props) => (props.isLastSegment ? 0 : space[3])}px;
    display: block;
  }

  ${createResponsiveBlock(screens.LAPTOP_LG)} {
    margin-right: ${(props) => (props.isLastSegment ? 0 : space[3])}px;
    display: block;
  }

  ${createResponsiveBlock(screens.DESKTOP)} {
    margin-right: ${(props) => (props.isLastSegment ? 0 : space[3])}px;
    display: block;
  }
`;

const TimelineSegment = ({ timeFrame, isLastSegment, ...props }) => (
  <TimelineBarContainer
    colorScheme="secondary"
    isLastSegment={isLastSegment}
    {...props}
  >
    <TimelineBar isLastSegment={isLastSegment} />
    <TimelineCaption
      colorScheme="white"
      boldness={2}
      textSize={0}
      m={[2, 0, 0, 0]}
      isLastSegment={isLastSegment}
    >
      {timeFrame}
    </TimelineCaption>
  </TimelineBarContainer>
);

TimelineSegment.propTypes = {
  timeFrame: PropTypes.string.isRequired,
  isLastSegment: PropTypes.bool,
};

TimelineSegment.defaultProps = {
  isLastSegment: false,
};

export default TimelineSegment;
