export const METROS = {
  SAN_FRANCISCO: 'san-francisco',
  NEW_YORK: 'new-york',
};

export const METROS_HUMAN_READABLE = {
  [METROS.SAN_FRANCISCO]: 'San Francisco, CA',
  [METROS.NEW_YORK]: 'New York, NY',
};

export const COMPANIES = {
  AIRBNB: 'airbnb',
  AMAZON: 'amazon',
  APPLE: 'apple',
  ASANA: 'asana',
  ATLASSIAN: 'atlassian',
  BOX: 'box',
  DROPBOX: 'dropbox',
  EBAY: 'ebay',
  EVERNOTE: 'evernote',
  FACEBOOK: 'facebook',
  GITHUB: 'github',
  GOOGLE: 'google',
  IBM: 'ibm',
  INSTACART: 'instacart',
  LINKEDIN: 'linkedin',
  LYFT: 'lyft',
  MICROSOFT: 'microsoft',
  PINTEREST: 'pinterest',
  SALESFORCE: 'salesforce',
  SLACK: 'slack',
  STRIPE: 'stripe',
  SQUARE: 'square',
  THUMBTACK: 'thumbtack',
  TWITTER: 'twitter',
  UBER: 'uber',
  YELP: 'yelp',
  YOUTUBE: 'youtube',
  ZENDESK: 'zendesk',
  OTHER: 'other',
};

export const COMPANIES_HUMAN_READABLE = {
  [COMPANIES.AIRBNB]: 'Airbnb, Inc.',
  [COMPANIES.AMAZON]: 'Amazon.com, Inc.',
  [COMPANIES.APPLE]: 'Apple, Inc.',
  [COMPANIES.ASANA]: 'Asana, Inc.',
  [COMPANIES.ATLASSIAN]: 'Atlassian Corporation Plc',
  [COMPANIES.BOX]: 'Box, Inc.',
  [COMPANIES.DROPBOX]: 'Dropbox, Inc.',
  [COMPANIES.EBAY]: 'eBay, Inc.',
  [COMPANIES.EVERNOTE]: 'Evernote Corporation',
  [COMPANIES.FACEBOOK]: 'Facebook, Inc.',
  [COMPANIES.GITHUB]: 'GitHub, Inc.',
  [COMPANIES.GOOGLE]: 'Google',
  [COMPANIES.IBM]: 'IBM',
  [COMPANIES.INSTACART]: 'Instacart',
  [COMPANIES.LINKEDIN]: 'LinkedIn',
  [COMPANIES.LYFT]: 'Lyft',
  [COMPANIES.MICROSOFT]: 'Microsoft Corporation',
  [COMPANIES.PINTEREST]: 'Pinterest Inc.',
  [COMPANIES.SALESFORCE]: 'Salesforce.com, Inc.',
  [COMPANIES.SLACK]: 'Slack Technologies',
  [COMPANIES.STRIPE]: 'Stripe, Inc.',
  [COMPANIES.SQUARE]: 'Square, Inc.',
  [COMPANIES.THUMBTACK]: 'Thumbtack, Inc.',
  [COMPANIES.TWITTER]: 'Twitter, Inc.',
  [COMPANIES.UBER]: 'Uber Technologies, Inc.',
  [COMPANIES.YELP]: 'Yelp, Inc.',
  [COMPANIES.YOUTUBE]: 'YouTube, LLC',
  [COMPANIES.ZENDESK]: 'Zendesk, Inc.',
  [COMPANIES.OTHER]: 'Other...',
};

export const COMPANIES_ORDER = [
  COMPANIES.AIRBNB,
  COMPANIES.AMAZON,
  COMPANIES.APPLE,
  COMPANIES.ASANA,
  COMPANIES.ATLASSIAN,
  COMPANIES.BOX,
  COMPANIES.DROPBOX,
  COMPANIES.EBAY,
  COMPANIES.EVERNOTE,
  COMPANIES.FACEBOOK,
  COMPANIES.GITHUB,
  COMPANIES.GOOGLE,
  COMPANIES.IBM,
  COMPANIES.INSTACART,
  COMPANIES.LINKEDIN,
  COMPANIES.LYFT,
  COMPANIES.MICROSOFT,
  COMPANIES.PINTEREST,
  COMPANIES.SALESFORCE,
  COMPANIES.SLACK,
  COMPANIES.STRIPE,
  COMPANIES.SQUARE,
  COMPANIES.THUMBTACK,
  COMPANIES.TWITTER,
  COMPANIES.UBER,
  COMPANIES.YELP,
  COMPANIES.YOUTUBE,
  COMPANIES.ZENDESK,
  COMPANIES.OTHER,
];

export default {
  METROS,
  METROS_HUMAN_READABLE,
  COMPANIES,
  COMPANIES_HUMAN_READABLE,
  COMPANIES_ORDER,
};
