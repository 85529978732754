import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ellipsis } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faNodeJs,
  faPython,
  faCss3,
  faReact,
} from '@fortawesome/free-brands-svg-icons';
import {
  faCode,
  faDrawCircle,
  faBoxFull,
  faClone,
} from '@fortawesome/pro-light-svg-icons';

import Label from '../../components/label';

import { colorSchemes, fontSizes, space, screens } from '../../design/theme';
import { createResponsiveBlock } from '../../design/util';

import TitledColumn from './titledColumn';

const ColumnContainer = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${colorSchemes.white.fg};
  font-size: ${fontSizes[2]}rem;
`;

const RowTitle = styled(Label.withComponent('h5'))`
  margin: 0;
  flex-grow: 0;
  min-width: 0;
  ${ellipsis()}
`;

const RowContent = styled.div`
  padding-top: ${space[2]}px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TitledSubcolumn = styled(({ title, children, className }) => (
  <div className={className}>
    <RowTitle
      textSize={1}
      boldness={2}
      p={[2, 0, 0, 0]}
      isAllCaps
      colorScheme="white"
    >
      {title}
    </RowTitle>
    <RowContent>{children}</RowContent>
  </div>
))`
  $parent-class: &;
  padding-right: ${space[4]}px;
  padding-top: 0;
  padding-bottom: ${space[3]}px;
  display: flex;
  flex-direction: column;
  min-width: 0;

  ${createResponsiveBlock(screens.MOBILE_SM)} {
    padding-right: 0;
  }

  ${createResponsiveBlock(screens.MOBILE_LG)} {
    padding-right: 0;
  }

  &:last-of-type {
    ${createResponsiveBlock(screens.TABLET)} {
      padding-right: 0;
    }
  }

  ${createResponsiveBlock(screens.DESKTOP)} {
    padding-top: ${space[2]}px;
    padding-bottom: 0;
  }
`;

TitledSubcolumn.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const SkillIcon = styled(({ icon, className }) => (
  <div className={className}>
    <Icon icon={icon} />
  </div>
))`
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  background-color: ${colorSchemes.secondary.fg};
  border-radius: 50px;
  text-align: center;
  flex-grow: 0;
  flex-shrink: 0;

  svg {
    color: ${colorSchemes.secondary.bg};
  }
`;

const SkillIconLabel = styled(Label)`
  ${ellipsis()};
`;

const SkillIconLabelContainer = styled.div`
  flex-grow: 1;
  padding-left: ${space[2]}px;
  min-width: 0;
`;

const SkillIconWithLabel = styled(({ label, icon, className }) => (
  <div className={className}>
    <SkillIcon icon={icon} />
    <SkillIconLabelContainer>
      <SkillIconLabel textSize={2} boldness={1} colorScheme="light">
        {label}
      </SkillIconLabel>
    </SkillIconLabelContainer>
  </div>
))`
  margin-bottom: ${space[3]}px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

SkillIconWithLabel.propTypes = {
  icon: PropTypes.shape().isRequired,
  label: PropTypes.string.isRequired,
};

const SubcolumnContainer = styled.div`
  display: flex;
  flex-grow: 0;
  min-width: 0;

  &:last-of-type {
    ${createResponsiveBlock(screens.TABLET)} {
      flex-direction: row;
      justify-content: space-around;
      flex-grow: 1;
    }

    ${createResponsiveBlock(screens.LAPTOP_SM)} {
      flex-direction: row;
      justify-content: space-around;
      flex-grow: 1;
    }

    ${createResponsiveBlock(screens.LAPTOP_LG)} {
      flex-direction: row;
      justify-content: space-around;
      flex-grow: 1;
    }

    ${createResponsiveBlock(screens.DESKTOP)} {
      flex-direction: row;
      justify-content: space-around;
      flex-grow: 1;
    }
  }
`;

const SkillsColumn = () => (
  <ColumnContainer>
    <TitledColumn title="Skills & Expertise" isHorizontallyResponsive>
      <SubcolumnContainer>
        <TitledSubcolumn title="Programming Languages">
          <SkillIconWithLabel icon={faNodeJs} label="Node & JS" />
          <SkillIconWithLabel icon={faPython} label="Python" />
          <SkillIconWithLabel icon={faCode} label="HTML" />
          <SkillIconWithLabel icon={faCss3} label="CSS" />
        </TitledSubcolumn>
      </SubcolumnContainer>
      <SubcolumnContainer>
        <TitledSubcolumn title="Frameworks">
          <SkillIconWithLabel icon={faReact} label="React" />
          <SkillIconWithLabel icon={faDrawCircle} label="Flux & Redux" />
          <SkillIconWithLabel icon={faBoxFull} label="Webpack" />
          <SkillIconWithLabel icon={faClone} label="ImmutableJS" />
        </TitledSubcolumn>
      </SubcolumnContainer>
    </TitledColumn>
  </ColumnContainer>
);

export default SkillsColumn;
