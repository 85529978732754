import React from 'react';
import { Waypoint } from 'react-waypoint';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import scrollSpyActions from '../../modules/scrollSpy/actions';

const Section = ({
  handleSectionEnteringScreen,
  handleSectionExitingScreen,
  id,
  colorScheme,
  ...props
}) => (
  <Waypoint
    onEnter={() => handleSectionEnteringScreen(id, colorScheme)}
    onLeave={() => handleSectionExitingScreen(id)}
    {...props}
  >
    {props.children}
  </Waypoint>
);

Section.propTypes = {
  children: PropTypes.node,
  handleSectionEnteringScreen: PropTypes.func.isRequired,
  handleSectionExitingScreen: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  colorScheme: PropTypes.string.isRequired,
};

Section.defaultProps = {
  children: null,
};

Section.displayName = 'Section';

const mapDispatchToProps = (dispatch) => ({
  handleSectionEnteringScreen: (sectionId, sectionColorScheme) =>
    dispatch(
      scrollSpyActions.handleSectionEnteringScreen(
        sectionId,
        sectionColorScheme,
      ),
    ),

  handleSectionExitingScreen: (sectionId, sectionColorScheme) =>
    dispatch(
      scrollSpyActions.handleSectionExitingScreen(
        sectionId,
        sectionColorScheme,
      ),
    ),
});

export default connect(null, mapDispatchToProps)(Section);
