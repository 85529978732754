import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FocusLock from 'react-focus-lock';
import { transparentize, lighten, darken } from 'polished';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';

import {
  colorSchemes,
  space,
  borderRadius,
  fontSizes,
  shadow,
  screens,
  pagePadding,
  pagePaddingLargeLaptop,
  pagePaddingDesktop,
} from '../../design/theme';
import { createResponsiveBlock } from '../../design/util';

import ContactMeActions from '../../modules/contactMe/actions';

import ContactMeDialog from './dialogs/contactMe';

function getDialogAnimationName({ isVisible }) {
  return isVisible ? 'isDialogVisible' : 'none';
}

const DialogWrapper = styled.div`
  background-color: ${colorSchemes.light.bg};
  margin: 0 auto;
  position: relative;
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  box-shadow: ${shadow};
  box-sizing: border-box;
  padding: ${space[pagePadding]}px;

  ${createResponsiveBlock(screens.TABLET)} {
    width: 100%;
    margin-top: 0;
  }

  ${createResponsiveBlock(screens.LAPTOP_SM)} {
    width: 75%;
    margin-bottom: 5%;
    animation-name: ${getDialogAnimationName};
    margin-top: ${(props) => (props.isVisible ? 10 : -100)}%;
    border-radius: ${borderRadius}px;
  }

  ${createResponsiveBlock(screens.LAPTOP_LG)} {
    width: 50%;
    margin-bottom: 10%;
    animation-name: ${getDialogAnimationName};
    margin-top: ${(props) => (props.isVisible ? 10 : -100)}%;
    border-radius: ${borderRadius}px;
    padding: ${space[pagePaddingLargeLaptop]}px;
  }

  ${createResponsiveBlock(screens.DESKTOP)} {
    width: 33%;
    margin-bottom: 10%;
    animation-name: ${getDialogAnimationName};
    margin-top: ${(props) => (props.isVisible ? 10 : -100)}%;
    border-radius: ${borderRadius}px;
    padding: ${space[pagePaddingDesktop]}px;
  }

  @keyframes isDialogVisible {
    0% {
      margin-top: -100%;
      opacity: 0;
    }

    100% {
      margin-top: 10%;
      opacity: 1;
    }
  }
`;

const DialogOverlay = styled.div`
  background-color: ${(props) =>
    transparentize(props.isVisible ? 0.1 : 1.0, colorSchemes.secondary.bg)};
  position: fixed;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 1s background-color ease-out;
`;

const CloseButton = styled(({ className, onClick }) => (
  <button onClick={onClick} className={className}>
    <FontAwesomeIcon icon={faTimesCircle} />
  </button>
))`
  background-color: transparent;
  text-align: left;
  padding: 0;
  font-size: ${fontSizes[4]}rem;
  line-height: 1rem;
  height: ${fontSizes[4]}rem;
  border-radius: 100%;
  color: ${colorSchemes.light.fg};
  border: 0;
  outline: none;
  transition: 1s color ease-in, 0.25s box-shadow ease-in-out;
  cursor: pointer;
  position: absolute;
  right: ${space[3]}px;
  top: ${space[3]}px;

  &:hover {
    color: ${lighten(0.25, colorSchemes.light.fg)};
  }

  &:active {
    transition-duration: 0.25s;
    color: ${darken(0.25, colorSchemes.light.fg)};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${transparentize(0.75, colorSchemes.light.fg)};
  }
`;

const DialogManager = styled(
  ({ isContactMeDialogShowing, onCloseContactMeDialog, className }) => (
    <div className={className}>
      <DialogOverlay isVisible={isContactMeDialogShowing} />
      <DialogWrapper
        isVisible={isContactMeDialogShowing}
        onLeave={onCloseContactMeDialog}
      >
        <FocusLock group="dialog">
          <CloseButton onClick={onCloseContactMeDialog} />
        </FocusLock>
        {isContactMeDialogShowing && (
          <FocusLock group="dialog" autoFocus>
            <ContactMeDialog isVisible={isContactMeDialogShowing} />
          </FocusLock>
        )}
      </DialogWrapper>
    </div>
  ),
)`
  height: 100%;
  width: 100%;
  z-index: ${(props) => (props.isContactMeDialogShowing ? 9999 : -1)};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition-property: z-index;
  transition-delay: ${(props) => (props.isContactMeDialogShowing ? 0 : 1)}s;
  transition-timing-function: steps(1);
  overflow-x: visible;
  overflow-y: auto;
`;

DialogManager.propTypes = {
  isContactMeDialogShowing: PropTypes.bool.isRequired,
  onCloseContactMeDialog: PropTypes.func.isRequired,
};

const mapStateToProps = ({ contactMeReducers }) => ({
  isContactMeDialogShowing: contactMeReducers.get('contactFlowActive'),
});

const mapDispatchToProps = (dispatch) => ({
  onCloseContactMeDialog: () =>
    ContactMeActions.handleContactFlowAbandoned(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DialogManager);
