import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { space, screens } from '../../design/theme';
import { createResponsiveBlock } from '../../design/util';

import Label from '../../components/label';

const ColumnTitleLabel = styled(Label.withComponent('h4'))`
  margin: 0;
  padding-top: ${space[5]}px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 0;
`;

const ColumnContent = styled.div`
  padding-top: ${space[2]}px;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${createResponsiveBlock(screens.TABLET)} {
    flex-direction: ${(props) =>
      props.isHorizontallyResponsive ? 'row' : null};
  }

  ${createResponsiveBlock(screens.LAPTOP_SM)} {
    flex-direction: ${(props) =>
      props.isHorizontallyResponsive ? 'row' : null};
  }

  ${createResponsiveBlock(screens.LAPTOP_LG)} {
    flex-direction: ${(props) =>
      props.isHorizontallyResponsive ? 'row' : null};
  }

  ${createResponsiveBlock(screens.DESKTOP)} {
    flex-direction: ${(props) =>
      props.isHorizontallyResponsive ? 'row' : null};
  }
`;

const TitledColumn = styled(
  ({ title, children, className, isHorizontallyResponsive }) => (
    <div className={className}>
      <ColumnTitleLabel textSize={4} boldness={2} colorScheme="secondary">
        {title}
      </ColumnTitleLabel>
      <ColumnContent isHorizontallyResponsive={isHorizontallyResponsive}>
        {children}
      </ColumnContent>
    </div>
  ),
)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

TitledColumn.propTypes = {
  ...TitledColumn.propTypes,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isHorizontallyResponsive: PropTypes.bool,
};

TitledColumn.defaultProps = {
  ...TitledColumn.defaultProps,
  isHorizontallyResponsive: false,
};

export default TitledColumn;
