/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/no-unresolved */
import React from 'react';
import styled from 'styled-components';

import {
  faJs,
  faPython,
  faReact,
  faSass,
  faMicrosoft,
  faNpm,
} from '@fortawesome/free-brands-svg-icons';
import {
  faGem,
  faTerminal,
  faLongArrowRight,
} from '@fortawesome/pro-light-svg-icons';

import Box from '../../components/box';
import Label from '../../components/label';
import Section from '../../components/section';
import {
  space,
  pagePadding,
  pagePaddingLargeLaptop,
  pagePaddingDesktop,
  screens,
} from '../../design/theme';
import { createResponsiveBlock } from '../../design/util';
import Project from './project';

import photoForOptimizelyProject from './photos/optimizely.jpg';
import photoForSecondOptimizelyProject from './photos/optimizely2.png';
import photoForGeneralAssemblyProject from './photos/generalAssembly.jpg';
import photoForHackBU from './photos/hackBU.jpg';
import photoForBlazonUi from './photos/blazonUI.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Container = styled(Box)`
  padding: 0 ${space[pagePadding]}px ${space[5]}px ${space[pagePadding]}px;

  ${createResponsiveBlock(screens.LAPTOP_LG)} {
    padding: 0 ${space[pagePaddingLargeLaptop]}px ${space[5]}px
      ${space[pagePaddingLargeLaptop]}px;
  }

  ${createResponsiveBlock(screens.DESKTOP)} {
    padding: 0 ${space[pagePaddingDesktop]}px ${space[5]}px
      ${space[pagePaddingDesktop]}px;
  }
`;

const SectionLabel = styled(Label.withComponent('h4'))`
  margin: 0;
`;

/* eslint-disable react/prefer-stateless-function */
class ProjectsSection extends React.Component {
  render() {
    return (
      <Section colorScheme="light" id="project-experience-section">
        <Container colorScheme="light" p={[0, pagePadding, 5, pagePadding]}>
          <SectionLabel
            textSize={5}
            colorScheme="secondary"
            boldness={2}
            p={[5, 0, 0, 0]}
          >
            Project Experience
          </SectionLabel>
          <Project
            title="Personal Project, 2019"
            headline="Published Blazon UI Library to NPM"
            description={
              <>
                To ease user interface development for a personal project, I
                created Blazon UI— a React component library published on NPM as
                an open source package.{' '}
                <a
                  target="_blank"
                  href="https://www.npmjs.com/package/blazon-ui"
                  title="See Blazon UI on NPM"
                >
                  Check it out&nbsp;
                  <FontAwesomeIcon icon={faLongArrowRight} />
                </a>
              </>
            }
            imageSrc={photoForBlazonUi}
            skills={[
              { name: 'React', icon: faReact },
              { name: 'Typescript', icon: faMicrosoft },
              { name: 'Javascript', icon: faJs },
              { name: 'NPM', icon: faNpm },
            ]}
          />
          <Project
            title="Optimizely Summer Hack Week, 2018"
            headline="Integrated Optimizely Feature Flags with Jira"
            description="As part of Optimizely's Hack Week, I created the frontend for an integrated workflow between Atlassian Jira Cloud and Optimizely Full Stack— a feature later announced at Opticon 2018."
            imageSrc={photoForSecondOptimizelyProject}
            isFlipped
            skills={[
              { name: 'React', icon: faReact },
              { name: 'Javascript', icon: faJs },
              { name: 'Python', icon: faPython },
            ]}
          />
          <Project
            title="Optimizely Summer Hack Week, 2017"
            headline="Created an Automated Onboarding Assistant"
            description="My intern class and I streamlined the onboarding process for new hires. With React, I wrote the frontend layer of our automated onboarding setup web application."
            imageSrc={photoForOptimizelyProject}
            skills={[
              { name: 'React', icon: faReact },
              { name: 'Javascript', icon: faJs },
              { name: 'Python', icon: faPython },
              { name: 'Bash', icon: faTerminal },
            ]}
          />
          <Project
            title="General Assembly Careers Page, 2016"
            headline="Implemented Redesign of Recruiting Page"
            description={
              <>
                As an intern, I developed a refactor of the careers page at
                General Assembly. Collaborating with PMs, designers, and API
                providers, I coded a React-based, redesigned career page.{' '}
                <a
                  target="_blank"
                  href="https://generalassemb.ly/careers"
                  title="See the page I worked on"
                >
                  See the page&nbsp;
                  <FontAwesomeIcon icon={faLongArrowRight} />
                </a>
              </>
            }
            imageSrc={photoForGeneralAssemblyProject}
            p={[5, 0, 0, 0]}
            isFlipped
            skills={[
              { name: 'React', icon: faReact },
              { name: 'Ruby on Rails', icon: faGem },
              { name: 'Javascript', icon: faJs },
              { name: 'Sass', icon: faSass },
            ]}
          />
          <Project
            title="RIT BrickHack II Hackathon, 2016"
            headline="Engineered an Artificially Intelligent Chef Bot"
            description="By combining a recipe API, an NLP API, and a chatbot, my hackathon team and I developed a recipe recommendation bot. As the team's programmer, I created a Rails application to connect these APIs and interact with the user."
            imageSrc={photoForHackBU}
            p={[5, 0, 0, 0]}
            skills={[
              { name: 'Ruby on Rails', icon: faGem },
              { name: 'Sass', icon: faSass },
              { name: 'Azure', icon: faMicrosoft },
            ]}
          />
        </Container>
      </Section>
    );
  }
}
/* eslint-enable react/prefer-stateless-function */

export default ProjectsSection;
