import { Map } from 'immutable';

import { SECTION_ORDER, SECTION_COLOR_SCHEMES } from './constants';

import { SECTION_ENTERED_SCREEN, SECTION_EXITED_SCREEN } from './actionTypes';

// Create default store state
let defaultState = Map();

SECTION_ORDER.forEach((section) => {
  defaultState = defaultState.set(section, false);
});

defaultState = defaultState
  .set('topSection', SECTION_ORDER[0])
  .set('colorScheme', SECTION_COLOR_SCHEMES[SECTION_ORDER[0]])
  .set(
    'visited',
    SECTION_ORDER.reduce(
      (reduction, section) => reduction.set(section, false),
      Map(),
    ),
  );

// Define action reducers
const scrollSpy = (state = defaultState, action) => {
  let newState = state;

  switch (action.type) {
    case SECTION_ENTERED_SCREEN:
      newState = newState.set(action.sectionId, true);
      break;

    case SECTION_EXITED_SCREEN:
      newState = newState.set(action.sectionId, false);
      break;

    default:
      break;
  }

  // Set all calculated values
  const topSection = SECTION_ORDER.find((section) => !!newState.get(section));

  newState = newState
    .set('topSection', topSection)
    .set('colorScheme', SECTION_COLOR_SCHEMES[topSection || SECTION_ORDER[0]]);

  if (topSection) {
    newState = newState.setIn(['visited', topSection], true);
  }

  return newState;
};

export default scrollSpy;
