/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RetinaImage from 'react-retina-image';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGithub,
  faLinkedin,
  faAngellist,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

import { isResumeAvailable } from '../../config.json';

import {
  screens,
  space,
  fontSizes,
  colorSchemes,
  pagePadding,
  pagePaddingLargeLaptop,
  pagePaddingDesktop,
} from '../../design/theme';
import { createResponsiveBlock } from '../../design/util';

import contactMeActions from '../../modules/contactMe/actions';

import Section from '../../components/section';
import Button from '../../components/button';

import shieldImage1x from './images/shield.png';
import shieldImage2x from './images/shield@2x.png';
import shieldImage3x from './images/shield@3x.png';

const socialMediaUrls = {
  github: 'https://github.com/zachpwr',
  linkedin: 'https://www.linkedin.com/in/zachpwr/',
  angellist: 'https://angel.co/zachary-power',
  instagram: 'https://www.instagram.com/zachpwr/',
  twitter: 'https://www.twitter.com/zachpwr/',
};

const FooterContainer = styled.div`
  display: flex;
  align-items: left;
  flex-direction: column;
  background-color: ${colorSchemes.light.fg};
  padding: ${space[4]}px ${space[pagePadding]}px;

  ${createResponsiveBlock(screens.LAPTOP_SM)} {
    flex-direction: row;
    align-items: center;
  }

  ${createResponsiveBlock(screens.LAPTOP_LG)} {
    padding: ${space[4]}px ${space[pagePaddingLargeLaptop]}px;
    flex-direction: row;
    align-items: center;
  }

  ${createResponsiveBlock(screens.DESKTOP)} {
    padding: ${space[4]}px ${space[pagePaddingDesktop]}px;
    flex-direction: row;
    align-items: center;
  }
`;

const ShieldImage = styled(RetinaImage).attrs({
  src: [shieldImage1x, shieldImage2x, shieldImage3x],
})`
  height: auto;
  width: 100px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const FooterColumn = styled.div`
  padding-top: ${space[4]}px;

  ${createResponsiveBlock(screens.LAPTOP_SM)} {
    flex-basis: 50%;
    padding-left: ${space[4]}px;
    padding-top: 0;
  }

  ${createResponsiveBlock(screens.LAPTOP_LG)} {
    flex-basis: 50%;
    padding-left: ${space[4]}px;
    padding-top: 0;
  }

  ${createResponsiveBlock(screens.DESKTOP)} {
    flex-basis: 50%;
    padding-left: ${space[4]}px;
    padding-top: 0;
  }
`;

const CopyrightStatement = styled.div`
  color: ${colorSchemes.light.bg};
  margin-bottom: ${space[2]}px;
  text-size: ${fontSizes[2]}rem;
`;

const ButtonLinkContainer = styled.div`
  margin-left: ${(-1 * space[2]) / 2}px;
`;

const SocialFooterColumn = styled(FooterColumn)`
  text-align: left;

  ${createResponsiveBlock(screens.LAPTOP_SM)} {
    text-align: right;
  }

  ${createResponsiveBlock(screens.LAPTOP_LG)} {
    text-align: right;
  }

  ${createResponsiveBlock(screens.DESKTOP)} {
    text-align: right;
  }
`;

const Link = styled.a`
  font-size: ${fontSizes[5]}rem;
  margin-left: ${space[2]}px;
  padding: ${space[1]}px;
  color: ${colorSchemes.light.bg};
`;

function goToPrivacyPolicy() {
  window.location.href = '/privacy';
}

const Footer = ({ onContactButtonClick }) => (
  <Section colorScheme="light" id="footer">
    <FooterContainer>
      <div>
        <ShieldImage />
      </div>
      <FooterColumn>
        <CopyrightStatement>
          Copyright &copy; {new Date().getFullYear()} Zachary Power -
          All&nbsp;Rights&nbsp;Reserved
        </CopyrightStatement>
        <ButtonLinkContainer>
          <Button m={[0, 2, 0, 0]} p={1} onClick={goToPrivacyPolicy} isLink>
            Privacy Policy &amp; User Agreement
          </Button>
          {isResumeAvailable && (
            <Button m={[0, 2, 0, 0]} p={1} isLink>
              Résumé
            </Button>
          )}
          <Button m={[0, 2, 0, 0]} p={1} onClick={onContactButtonClick} isLink>
            Contact Me
          </Button>
        </ButtonLinkContainer>
      </FooterColumn>
      <SocialFooterColumn>
        <Link href={socialMediaUrls.github} title="@zachpwr on Github">
          <FontAwesomeIcon icon={faGithub} />
        </Link>
        <Link href={socialMediaUrls.linkedin} title="@zachpwr on LinkedIn">
          <FontAwesomeIcon icon={faLinkedin} />
        </Link>
        <Link
          href={socialMediaUrls.angellist}
          title="@zachary-power on AngelList"
        >
          <FontAwesomeIcon icon={faAngellist} />
        </Link>
        <Link href={socialMediaUrls.instagram} title="@zachpwr on Instagram">
          <FontAwesomeIcon icon={faInstagram} />
        </Link>
        <Link href={socialMediaUrls.twitter} title="@zachpwr on Twitter">
          <FontAwesomeIcon icon={faTwitter} />
        </Link>
      </SocialFooterColumn>
    </FooterContainer>
  </Section>
);

Footer.propTypes = {
  onContactButtonClick: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  onContactButtonClick: () =>
    contactMeActions.handleContactFlowInitiated(dispatch),
});

export default connect(null, mapDispatchToProps)(Footer);
