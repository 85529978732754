import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lighten } from 'polished';
import { Waypoint } from 'react-waypoint';

import Box from '../../components/box';
import Label from '../../components/label';

import {
  borderRadius,
  shadow,
  colorSchemes,
  fontSizes,
  screens,
  space,
} from '../../design/theme';
import {
  getBoxEffects,
  getMargin,
  getPadding,
  createResponsiveBlock,
  joinSpaceValueList,
} from '../../design/util';

const ProjectContainer = styled.div`
  ${getPadding}
  display: flex;

  ${createResponsiveBlock(screens.MOBILE_SM)} {
    flex-direction: ${(props) =>
      props.isFlipped ? 'column-reverse' : 'column'};
  }

  ${createResponsiveBlock(screens.MOBILE_LG)} {
    flex-direction: ${(props) =>
      props.isFlipped ? 'column-reverse' : 'column'};
  }

  ${createResponsiveBlock(screens.TABLET)} {
    flex-direction: ${(props) =>
      props.isFlipped ? 'column-reverse' : 'column'};
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  border-radius: ${borderRadius}px;
  box-shadow: ${shadow};
`;

const ProjectImageColumn = styled.div`
  ${getBoxEffects}
  ${getMargin}
  align-self: center;
  flex-basis: ${(7.0 / 12.0) * 100.0}%;

  ${createResponsiveBlock(screens.MOBILE_SM)} {
    padding: 0px 0px ${space[4]}px 0px;
  }

  ${createResponsiveBlock(screens.MOBILE_LG)} {
    padding: 0px 0px ${space[4]}px 0px;
  }

  ${createResponsiveBlock(screens.TABLET)} {
    padding: 0px 0px ${space[4]}px 0px;
  }

  ${createResponsiveBlock(screens.LAPTOP_SM)} {
    padding: ${(props) =>
      joinSpaceValueList(
        [0, props.isFlipped ? 0 : 4, 0, props.isFlipped ? 4 : 0],
        space,
      )};
  }

  ${createResponsiveBlock(screens.LAPTOP_LG)} {
    padding: ${(props) =>
      joinSpaceValueList(
        [0, props.isFlipped ? 0 : 4, 0, props.isFlipped ? 4 : 0],
        space,
      )};
  }

  ${createResponsiveBlock(screens.DESKTOP)} {
    padding: ${(props) =>
      joinSpaceValueList(
        [0, props.isFlipped ? 0 : 4, 0, props.isFlipped ? 4 : 0],
        space,
      )};
  }

  ${ProjectImage} {
    transition: 0.5s transform ease-in-out;
    transform: scale(${({ isMagnified }) => (isMagnified ? 1.03 : 1.0)});
  }
`;

ProjectImageColumn.propTypes = {
  isMagnified: PropTypes.bool.isRequired,
  isFlipped: PropTypes.bool.isRequired,
  ...ProjectImageColumn.propTypes,
};

const TextColumn = styled.div`
  ${getBoxEffects}
  align-self: center;
  flex-basis: ${(5.0 / 12.0) * 100}%;
  max-width: 100%;

  & > * {
    transition: 0.65s transform ease-in-out;
    transform: scale(${({ isMagnified }) => (isMagnified ? 1.01 : 1.0)});
  }
`;

TextColumn.propTypes = {
  isMagnified: PropTypes.bool.isRequired,
};

const TitleLabel = styled(Label)`
  line-height: 1.4em;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${colorSchemes.white.fg};
  font-size: ${fontSizes[2]}rem;
`;

const SkillIcon = styled(({ icon, ...props }) => (
  <div {...props}>
    <Icon icon={icon} />
  </div>
))`
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
  border-radius: 50px;
  border: 1px solid ${colorSchemes.accent.bg};
  box-sizing: border-box;
  text-align: center;
  margin: 0 auto;

  svg {
    color: ${colorSchemes.accent.bg};
  }
`;

const SkillLabel = styled(Label)`
  display: block;
  width: 100%;
  text-align: center;
  color: ${lighten(0.25, colorSchemes.light.fg)};
  margin-bottom: ${space[2]}px;
`;

const SkillIconContainer = styled(Box)`
  max-width: 100%;
  flex-wrap: wrap;
`;

export default class Project extends React.Component {
  constructor(props) {
    super(props);

    this.handleSectionEnter = this.handleSectionEnter.bind(this);
    this.handleSectionLeave = this.handleSectionLeave.bind(this);

    this.state = {
      isMagnified: false,
    };
  }

  handleSectionEnter() {
    this.setState({ isMagnified: true });
  }

  handleSectionLeave() {
    this.setState({ isMagnified: false });
  }

  render() {
    const {
      imageSrc,
      isFlipped,
      title,
      headline,
      description,
      skills,
    } = this.props;

    const { isMagnified } = this.state;

    const image = (
      <ProjectImageColumn isMagnified={isMagnified} isFlipped={isFlipped}>
        <ProjectImage src={imageSrc} m={[0, 2, 0, 0]} />
      </ProjectImageColumn>
    );

    const skillsIcons = skills.map(({ name, icon }) => (
      <Box
        m={[0, 3, 0, 0]}
        colorScheme="light"
        isColumn
        key={`skill-icon-${name}`}
      >
        <SkillIcon icon={icon} />
        <SkillLabel boldness={1} m={[1, 0, 0, 0]}>
          {name}
        </SkillLabel>
      </Box>
    ));

    return (
      <Waypoint
        onLeave={() => this.handleSectionLeave()}
        onEnter={() => this.handleSectionEnter()}
        topOffset="55%"
        bottomOffset="60%"
      >
        <ProjectContainer isContainer p={[4, 0, 0, 0]} {...this.props}>
          {isFlipped ? null : image}
          <TextColumn isMagnified={isMagnified}>
            <Label
              textSize={1}
              colorScheme="white"
              isAllCaps
              boldness={2}
              m={[0, 0, 2, 0]}
            >
              {title}
            </Label>
            <TitleLabel
              textSize={6}
              colorScheme="light"
              boldness={2}
              m={[0, 0, 2, 0]}
            >
              {headline}
            </TitleLabel>
            <TitleLabel
              textSize={3}
              colorScheme="light"
              boldness={1}
              m={[0, 0, 1, 0]}
            >
              {description}
            </TitleLabel>
            <Label
              textSize={1}
              colorScheme="light"
              isAllCaps
              boldness={2}
              m={[3, 0, 2, 0]}
            >
              Skills Used
            </Label>
            <SkillIconContainer colorScheme="light" isContainer>
              {skillsIcons}
            </SkillIconContainer>
          </TextColumn>
          {isFlipped ? image : null}
        </ProjectContainer>
      </Waypoint>
    );
  }
}

Project.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  isFlipped: PropTypes.bool,
  title: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
        PropTypes.string,
      ]),
    }),
  ).isRequired,
};

Project.defaultProps = {
  isFlipped: false,
};
