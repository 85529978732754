import React from 'react';
import styled from 'styled-components';

import Label from '../../components/label';
import Section from '../../components/section';

import {
  pagePadding,
  pagePaddingLargeLaptop,
  pagePaddingDesktop,
  colorSchemes,
  space,
  screens,
} from '../../design/theme';
import { createResponsiveBlock } from '../../design/util';

import Timeline from './timeline/index';

const SectionContentWrapper = styled.div`
  background-color: ${colorSchemes.secondary.bg};
  padding: 0 ${space[pagePadding]}px;

  ${createResponsiveBlock(screens.LAPTOP_LG)} {
    padding: 0 ${space[pagePaddingLargeLaptop]}px;
  }

  ${createResponsiveBlock(screens.DESKTOP)} {
    padding: 0 ${space[pagePaddingDesktop]}px;
  }
`;

const SectionTitleLabel = styled(Label.withComponent('h4'))`
  margin: 0;
`;

/* eslint-disable react/prefer-stateless-function */
class WorkExperienceSection extends React.Component {
  render() {
    return (
      <Section colorScheme="secondary" id="work-experience-section">
        <SectionContentWrapper>
          <SectionTitleLabel
            textSize={5}
            colorScheme="secondary"
            boldness={2}
            p={[5, 0, 0, 0]}
          >
            Work Experience
          </SectionTitleLabel>
          <Timeline />
        </SectionContentWrapper>
      </Section>
    );
  }
}
/* eslint-enable react/prefer-stateless-function */

export default WorkExperienceSection;
