import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-light-svg-icons';
import { connect } from 'react-redux';

import Box from '../box';
import Button from '../button';
import Label from '../label';

import { actions as uiActions } from '../../modules/ui';

import {
  pagePadding,
  pagePaddingLargeLaptop,
  pagePaddingDesktop,
  screens,
  fontSizes,
  space,
} from '../../design/theme';
import { createResponsiveBlock } from '../../design/util';

const NavContainer = styled(Box)`
  padding: ${space[3]}px ${space[pagePadding]}px;

  ${createResponsiveBlock(screens.LAPTOP_LG)} {
    padding: ${space[3]}px ${space[pagePaddingLargeLaptop]}px;
  }

  ${createResponsiveBlock(screens.DESKTOP)} {
    padding: ${space[3]}px ${space[pagePaddingDesktop]}px;
  }
`;

const NavColumn = styled(Box)`
  align-self: center;
  background-color: transparent;
  text-align: ${(props) => props.alignDirection};
  flex-grow: ${(props) => props.flexGrow};

  .contact-me-button {
    ${createResponsiveBlock(screens.MOBILE_SM)} {
      font-size: ${fontSizes[0]}em;
    }
  }
`;

NavColumn.propTypes = {
  alignDirection: PropTypes.oneOf(['right', 'center', 'left']),
};

const CondensedNavWrapper = styled.div`
  display: none;

  ${createResponsiveBlock(screens.TABLET)} {
    display: inline-block;
  }
`;

const ExpandedNavWrapper = styled.div`
  display: none;

  ${createResponsiveBlock(screens.LAPTOP_SM)} {
    display: inline-block;
  }

  ${createResponsiveBlock(screens.LAPTOP_LG)} {
    display: inline-block;
  }

  ${createResponsiveBlock(screens.DESKTOP)} {
    display: inline-block;
  }
`;

const LogoLabel = styled(Label)`
  font-size: ${fontSizes[5]}em;

  ${createResponsiveBlock(screens.TABLET)} {
    font-size: ${fontSizes[4]}em;
  }

  ${createResponsiveBlock(screens.MOBILE_LG)} {
    font-size: ${fontSizes[3]}em;
  }

  ${createResponsiveBlock(screens.MOBILE_SM)} {
    font-size: ${fontSizes[1]}em;
  }
`;

const Nav = ({
  colorScheme,
  onContactButtonClick,
  onMobileNavOpen,
  onNavigate,
  isTransparent,
  isVisible,
}) => (
  <NavContainer
    isContainer
    isTransparent={isTransparent}
    colorScheme={colorScheme}
    shouldFade
  >
    <NavColumn
      colorScheme={colorScheme}
      flexGrow={0}
      m={[0, 1, 0, 0]}
      shouldFade
    >
      <LogoLabel colorScheme={colorScheme} boldness={2} isAllCaps>
        Zach Power
      </LogoLabel>
    </NavColumn>
    <NavColumn
      colorScheme={colorScheme}
      flexGrow={1}
      alignDirection="right"
      shouldFade
    >
      <ExpandedNavWrapper>
        <Button
          colorScheme={colorScheme}
          textSize={1}
          onClick={() => onNavigate('#workExperience')}
          isLink
          p={1}
          m={[0, 2, 0, 0]}
          tabIndex={isVisible ? null : -1}
        >
          Work Experience
        </Button>
        <Button
          colorScheme={colorScheme}
          textSize={1}
          onClick={() => onNavigate('#projects')}
          isLink
          p={1}
          m={[0, 2, 0, 0]}
          tabIndex={isVisible ? null : -1}
        >
          Projects
        </Button>
        <Button
          colorScheme={colorScheme}
          textSize={1}
          onClick={() => onNavigate('#profileDetails')}
          isLink
          p={1}
          m={[0, 2, 0, 0]}
          tabIndex={isVisible ? null : -1}
        >
          Skills
        </Button>
      </ExpandedNavWrapper>
      <CondensedNavWrapper>
        <Button
          colorScheme={colorScheme}
          textSize={1}
          onClick={onMobileNavOpen}
          isLink
          p={1}
          m={[0, 2, 0, 0]}
          tabIndex={isVisible ? null : -1}
        >
          <FontAwesomeIcon icon={faBars} />
        </Button>
      </CondensedNavWrapper>
      <Button
        textSize={1}
        colorScheme={colorScheme}
        onClick={onContactButtonClick}
        p={[1, 2]}
        tabIndex={isVisible ? null : -1}
        className="contact-me-button"
      >
        Contact Me
      </Button>
    </NavColumn>
  </NavContainer>
);

Nav.propTypes = {
  colorScheme: PropTypes.string,
  onContactButtonClick: PropTypes.func,
  onMobileNavOpen: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  isTransparent: PropTypes.bool,
};

Nav.defaultProps = {
  colorScheme: 'primary',
  onContactButtonClick: () => {},
  isTransparent: false,
};

Nav.displayName = 'Nav';

const mapDispatchToProps = (dispatch) => ({
  onMobileNavOpen: () => uiActions.openSidebarNav(dispatch),
});

export default connect(null, mapDispatchToProps)(Nav);
